import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Accordion, Row, Col, Card, Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../App';
import Navigationbar from '../../components/navigationbar/NavigationBar';
import style from './landing.module.css';
import useThumbnailUrl from '../../services/useThumbnailUrl';

export default function LandingpageIndex() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state, dispatch } = useContext(AuthContext);
    const [module, setModule] = useState([]);
    const [savedVideo, setSavedVideo] = useState([]);
    const [moduleLoading, setModuleLoading] = useState(true);
    const [locale] = useState(localStorage.getItem('locale') == null ? 'id' : localStorage.getItem('locale'));
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()

    useEffect(() => {
        const fetchData = () => {
            var config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.token
                }
            }
            axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/${locale}/module`, config)
                .then(res => {
                    setModuleLoading(false);
                    setModule(res.data);
                })
                .catch(e => {
                    console.log(e)
                })
        }
        fetchData()
    }, [locale, state.token])

    useEffect(() => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }
        async function getSavedVideo() {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/${locale}/library/user_saved_video`, config)

                if (res.data == null) {
                    setSavedVideo([]);
                } else {
                    setSavedVideo(res.data);
                }
            } catch (error) {
                console.error(error);
            }
        }
        getSavedVideo()
    }, [locale, state.token])

    useEffect(() => {
        const timeout = () => {
            setTimeout(() => {
                console.log("Token telah berakhir")
                dispatch({
                    type: "LOGOUT"
                })
            }, state.tokenExpires)
        }
        timeout()
    }, [dispatch, state.tokenExpires])

    const GetThumbnailUrl = (url) => {
        const { thumbnailUrl } = useThumbnailUrl(url);
        return thumbnailUrl;
    }

    function handleOpenSavedVideo(journey_id, video_id) {
        navigate(`/journey/${journey_id}/video/${video_id}`);
    }

    if (!state.isAuthenticated) {
        return <Navigate to={window.location = process.env.REACT_APP_GENED_LEARN_URL} />
    }


    return (
        <div>
            <Navigationbar />
            <div className='container my-5'>
                <Row>
                    <Col md={12}>
                        {
                            savedVideo.length >= 1 &&
                            <>
                                <h3>{t('view.saved.videos')}</h3>
                                <Row className={style['saved-videos']}>
                                    {savedVideo.map((item, index) =>
                                        <Col xl={2} md={3} sm={12} key={index}>
                                            <Card 
                                                className='mb-4'
                                                style={{cursor: 'pointer'}} 
                                                onClick={(e) => handleOpenSavedVideo(item.journey_id, item.video_id)}
                                            >
                                                <Card.Img style={{ maxHeight: 120, objectFit: 'cover' }} variant="top" src={GetThumbnailUrl(item.video_url)} />
                                                <Card.Body>
                                                    <Card.Title>{item.title}</Card.Title>
                                                    <Card.Text>
                                                        {item.description}
                                                    </Card.Text>
                                                    <div className="d-grid gap-2">
                                                        <Button variant="dark">
                                                            {t('see.details')}
                                                        </Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </>

                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h4>{t('explore.by.module')}</h4>
                        {moduleLoading &&
                            <Row className='mt-4'>
                                <Col md={4}>
                                    <Skeleton height={250} />
                                </Col>
                            </Row>
                        }
                        {!moduleLoading &&
                            <Row className='mt-4'>
                                <Col md={4}>
                                    <Accordion defaultActiveKey="0" className={style['module-wrapper']}>
                                        {
                                            module.map((module, index) =>
                                                <Accordion.Item eventKey={index} key={index}>
                                                    <Accordion.Header onClick={executeScroll}>
                                                        <img src={module.img_url} alt={module.module_name} style={{ minHeight: 160 }} />
                                                        <div className={style['module-name']}>{module.module_name}</div>
                                                    </Accordion.Header>
                                                    <Accordion.Body className={style['journey-list']} style={{ minHeight: '100%' }}>
                                                        {
                                                            module.journeys.map((journey, index) => {
                                                                return journey.count_library_content > 0 ?
                                                                    <Link className={style['journey-list-item']} key={index} to={`/journey/${journey.journey_id}`}>{journey.journey_name}</Link>
                                                                    :
                                                                    <Link className={style['journey-list-item-disabled']} key={index} to={`/journey/${journey.journey_id}`}>{journey.journey_name}</Link>
                                                            })
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        }
                                    </Accordion>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
            <div ref={myRef} style={{ marginTop: 200 }} />
        </div>
    );
}