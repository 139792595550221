import React, { useState, useEffect, useContext } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import NavigationBar from '../../components/navigationbar/NavigationBar';
import axios from 'axios';
import ReactPlayer from 'react-player';
import Tab from 'react-bootstrap/Tab';
import { Row, Col, Nav, Alert, Button, Spinner, Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../App';
import { MdOutlinePlaylistAdd, MdOutlinePlaylistAddCheck, MdArrowBack } from "react-icons/md";
import style from './Video.module.css';

export default function Video() {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state, dispatch } = useContext(AuthContext);
    const [journeyVideo, setJourneyVideo] = useState({});
    const [videoTranscript, setVideoTranscript] = useState([]);
    const [savedVideoStatus, setSavedVideoStatus] = useState(true);
    const [showSavedVideoAlert, setShowSavedVideoAlert] = useState(false);
    const [saveVideoLoading, setSaveVideoLoading] = useState(false);
    const [setLoading] = useState(false);
    const [locale] = useState(localStorage.getItem('locale') == null ? 'id' : localStorage.getItem('locale'));

    const fetchData = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }
        axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/${locale}/library/video/` + id + ``, config)
            .then(res => {
                setJourneyVideo(res.data);
                if (res.data.saved === 1) {
                    setSavedVideoStatus(false);
                } else {
                    setSavedVideoStatus(true);
                }
                setVideoTranscript(res.data.library_video_transcripts);
                setLoading(true);
            })
            .catch(e => {
                console.log(e)
            })
    }

    const saveVideo = () => {
        setSaveVideoLoading(true);
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }
        let payload = {}
        payload['video_id'] = id;
        axios.post(`${process.env.REACT_APP_BASE_URL}api/library/video/save`, payload, config)
            .then(function () {
                console.log('sukses');
                setSaveVideoLoading(false);
            })
            .catch(function (error) {
                setSaveVideoLoading(false);
            });

        if (savedVideoStatus) {
            setSavedVideoStatus(true)
            setShowSavedVideoAlert(true);
        } else {
            setSavedVideoStatus(false)
            setShowSavedVideoAlert(false);
        }
    }

    const handleCloseAlert = () => {
        setShowSavedVideoAlert(false);
    }

    const timeout = () => {
        setTimeout(() => {
            console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
        timeout()
        // eslint-disable-next-line
    }, [saveVideoLoading])

    if (!state.isAuthenticated) {
        return <Navigate to={window.location = process.env.REACT_APP_GENED_LEARN_URL} />
    }

    const handleBack = () => {
        navigate(-1);
    };

    const handleVideoStart = (id) => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        const requestBody = {
            video_id: id,
            video_completed: 0
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}api/library/video/update-progress`, requestBody, config)
            .then(res => {
                console.log(res)
            })
            .catch(e => {
                console.log(e)
            })
    }

    const handleVideoEnd = (id) => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        const requestBody = {
            video_id: id,
            video_completed: 1
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}api/library/video/update-progress`, requestBody, config)
            .then(res => {
                console.log(res)
            })
            .catch(e => {
                console.log(e)
            })
    }

    return (
        <div>
            <NavigationBar />
            <Container>
                <Row>
                    <Col>
                        <Button onClick={handleBack} className='mb-4' variant="light"><MdArrowBack size={18} style={{ marginRight: 8, marginBottom: 2 }} />Kembali</Button>
                        <div className={style['iframe-wrapper']}>
                            <ReactPlayer
                                onStart={() => handleVideoStart(journeyVideo.id)}
                                onEnded={() => handleVideoEnd(journeyVideo.id)}
                                url={journeyVideo.video_url}
                                width='100%'
                                height='100%'
                                controls={true}
                            />
                        </div>
                        <div className={style['video-title-wrapper']}>
                            <h2 className='mt-4'>{journeyVideo.title}</h2>
                            <div className='d-flex align-items-center' role='button' onClick={saveVideo}>
                                {savedVideoStatus ?
                                    <div className='d-flex flex-row align-items-center'>
                                        <Button disabled={saveVideoLoading} variant="light">
                                            {saveVideoLoading ?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    style={{ marginRight: 6, borderWidth: 2 }}
                                                />
                                                :
                                                <MdOutlinePlaylistAdd size={24} style={{ marginRight: 6 }} />
                                            }
                                            {t('save.video')}
                                        </Button>
                                    </div>
                                    :
                                    <div className='d-flex flex-row align-items-center'>
                                        <Button disabled={saveVideoLoading} variant="light">
                                            {saveVideoLoading ?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    style={{ marginRight: 6, borderWidth: 2 }}
                                                />
                                                :
                                                <MdOutlinePlaylistAddCheck size={24} style={{ marginRight: 6 }} />
                                            }
                                            {t('video.saved')}
                                        </Button>
                                    </div>
                                }
                            </div>
                        </div>

                        {showSavedVideoAlert &&
                            <Alert variant="success" dismissible onClose={handleCloseAlert}>
                                <Alert.Heading>Video Berhasil Tersimpan!</Alert.Heading>
                                <p>
                                    Video yang tersimpan akan muncul di halaman depan
                                </p>
                            </Alert>
                        }

                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row className='mt-4'>
                                <Col sm={3} className='px-2'>
                                    <Nav variant="pills" className={style['video-details-nav']}>
                                        <Nav.Item>
                                            <Nav.Link className={style['video-detail-menu']} eventKey="video-analysis">{t('video.analysis')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link className={style['video-detail-menu']} eventKey="objectives">{t('objectives')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='mt-2'>
                                            <div className={style['video-detail-menu']}>{t('video.transcript')}</div>
                                            {
                                                videoTranscript.map((item, index) =>
                                                    <Nav.Item key={index} className={style['video-detail-submenu']}>
                                                        <Nav.Link eventKey={item.order}>{item.name}</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            }
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="video-analysis">
                                            <div dangerouslySetInnerHTML={{ __html: journeyVideo.analysis }} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="objectives">
                                            {journeyVideo.objective}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="video-transcript">Video Transcript
                                        </Tab.Pane>
                                        {
                                            videoTranscript.map((item, index) =>
                                                <Tab.Pane key={index} eventKey={item.order}>
                                                    {item.value}
                                                </Tab.Pane>
                                            )
                                        }
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
