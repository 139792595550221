import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Navigate, useParams } from "react-router-dom";
import NavigationBar from '../../components/navigationbar/NavigationBar';
import axios from 'axios';
import { Row, Col, Button, Card, Container } from 'react-bootstrap';
import { AuthContext } from '../../App';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EbookIllustration from '../../assets/images/ebook.svg';
import style from './ExploreJourney.module.css';
import useThumbnailUrl from '../../services/useThumbnailUrl';
import EmptyModuleState from '../../components/emptyState/EmptyModuleState';
import { useTranslation } from 'react-i18next';
import { MdArrowBack } from "react-icons/md";

function ExploreJourney() {
    const { state, dispatch } = useContext(AuthContext);
    const [journey, setJourney] = useState({});
    const [journeyVideo, setJourneyVideo] = useState([]);
    const [journeyEbook, setJourneyEbook] = useState([]);
    const [journeyVideoEmpty, setJourneyVideoEmpty] = useState(false);
    const [journeyEbookEmpty, setJourneyEbookEmpty] = useState(false);
    const [setLoading] = useState(false);
    const [locale] = useState(localStorage.getItem('locale') == null ? 'id' : localStorage.getItem('locale'));
    const { id } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }
        axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/${locale}/library/journey/` + id + `/video`, config)
            .then(res => {
                // setLoading(false);
                console.log('wee' + res.data.journey)
                if (res.data.journey === undefined || res.data.journey === null) {
                    setJourneyVideoEmpty(true)
                } else {
                    setJourneyVideoEmpty(false)
                    setJourney(res.data.journey)
                }
                if (res.data.library_videos === undefined || res.data.library_videos === null) {
                    setJourneyEbookEmpty(true)
                } else {
                    setJourneyEbookEmpty(false)
                    setJourneyVideo(res.data.library_videos);
                }
            })
            .catch(e => {
                console.log(e)
            })

        axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/${locale}/library/journey/` + id + `/documents`, config)
            .then(res => {
                setJourneyEbook(res.data.library_documents);
                // setLoading(true);
            })
            .catch(e => {
                console.log(e)
            })

        setTimeout(() => {
            console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }, [dispatch, locale, id, setLoading, state.token, state.tokenExpires])

    const navigate = useNavigate();
    function handleClick(data) {
        navigate(`/journey/${id}/video/${data}`);
    }

    if (!state.isAuthenticated) {
        return <Navigate to={window.location = process.env.REACT_APP_GENED_LEARN_URL} />
    }

    const HandleEbookOpen = (id, url) => {
        // Update progress
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }
        const requestBody = {
            document_id: id
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}api/library/document/update-progress`, requestBody, config)
            .then(res => {
                console.log(res)
            })
            .catch(e => {
                console.log(e)
            })

        // Open ebook in new tab
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const GetThumbnailUrl = (url) => {
        const { thumbnailUrl } = useThumbnailUrl(url);
        return thumbnailUrl;
    }

    const handleBack = () => {
        navigate('/', { replace: true });
    };


    return (
        <div>
            <NavigationBar />
            <Container>
                <Row>
                    <Col>
                        <Button onClick={handleBack} className='mb-4' variant="light"><MdArrowBack size={18} style={{ marginRight: 8, marginBottom: 2 }} />Kembali</Button>
                        <h1>
                            {journey?.journey_name}
                        </h1>
                        <Row>
                            <Tabs
                                defaultActiveKey="video"
                                className="mb-3"
                                justify
                            >
                                <Tab eventKey="video" title="Video">
                                    {journeyVideoEmpty &&
                                        <EmptyModuleState
                                            type={'video'}
                                        />
                                    }
                                    <Row>
                                        {
                                            journeyVideo && journeyVideo.map((item, index) =>
                                                <Col md='4' key={index} className={style['video-item']} onClick={(e) => handleClick(item.video_id)}>
                                                    <Card className='mb-4'>
                                                        <Card.Img style={{ maxHeight: 200, objectFit: 'cover' }} variant="top" src={GetThumbnailUrl(item.video_url)} />
                                                        <Card.Body>
                                                            <Card.Title>{item.title}</Card.Title>
                                                            <Card.Text>
                                                                {item.description}
                                                            </Card.Text>
                                                            <div className="d-grid gap-2">
                                                                <Button variant="dark">
                                                                    {t('see.details')}
                                                                </Button>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Tab>
                                <Tab eventKey="ebook" title="Ebook">
                                    {journeyEbookEmpty &&
                                        <EmptyModuleState
                                            type={'ebook'}
                                        />
                                    }

                                    <Row>
                                        {
                                            journeyEbook && journeyEbook.map((item, index) =>
                                                <Col md='4' key={index} className={style['ebook-item']} onClick={() => HandleEbookOpen(item.id, item.document_url)}>
                                                    <Card className='mb-4'>
                                                        <Card.Img variant="top" src={EbookIllustration} className='mt-3 mx-4' style={{ maxWidth: 120 }} />
                                                        <Card.Body>
                                                            <Card.Title>{item.title}</Card.Title>
                                                            <div className="d-grid gap-2 mt-3">
                                                                <Button variant="dark">
                                                                    Open Ebook
                                                                </Button>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Row>
                        {/* </div> */}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ExploreJourney;