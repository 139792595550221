// Constants.js
const prod = {
    url: {
        COOKIE_DOMAIN: '.gened.info',
    }
};
const dev = {
    url: {
        COOKIE_DOMAIN: 'localhost'
    }
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;