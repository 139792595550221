import React from 'react'
import { useTranslation } from 'react-i18next';
import Lottie from "react-lottie"
import emptyStateIllustration from '../../assets/lottie/empty-state.json';
import style from './EmptyModuleState.module.css';

export default function EmptyModuleState({ type }) {
    const { t } = useTranslation();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: emptyStateIllustration,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <div className={style['empty-module']}>
            <Lottie
                options={defaultOptions}
                height={200}
                width={200}
            />
            {type === 'video' && <div>{t('empty.video.module')}</div>}
            {type === 'ebook' && <div>{t('empty.ebook.module')}</div>}
        </div>
    )
}
