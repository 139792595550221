import React, { useReducer, createContext } from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import Cookies from 'js-cookie';
import './i18n';
import './App.css';
import LandingpageIndex from './pages/landingpage/LandingpageIndex';
import ExploreJourney from './pages/journey/ExploreJourney';
import Video from './pages/video/Video';
import { config } from './constants/constants';

var cookie_domain = config.url.COOKIE_DOMAIN

export const AuthContext = createContext();

const initialState = {
  isAuthenticated: Cookies.get('user') != null ? true : false,
  user: Cookies.get('user', { path: '/', domain: cookie_domain }),
  token: Cookies.get('token', { path: '/', domain: cookie_domain }) == null ? null : Cookies.get('token', { path: '/', domain: cookie_domain }),
  tokenExpires: Cookies.get('expires_in', { path: '/', domain: cookie_domain }) == null ? null : Cookies.get('expires_in', { path: '/', domain: cookie_domain }),
  userProgress: localStorage.getItem('userProgress') == null ? null : JSON.parse(localStorage.getItem('userProgress'))
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      Cookies.set('user', JSON.stringify(action.payload.user))
      Cookies.set('token', action.payload.token.access_token)
      Cookies.set('expires_in', JSON.stringify(action.payload.token.expires_in))

      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token.access_token,
        tokenExpires: action.payload.token.expires_in,
        userProgress: action.payload.userProgress
      }

    case "FORGOT_PASSWORD":
      localStorage.setItem("email", JSON.stringify(action.payload))
      return {
        ...state,
        email: action.payload
      }

    case "USER_PROGRESS":
      localStorage.setItem("userProgress", JSON.stringify(action.payload))
      return {
        ...state,
        userProgress: action.payload
      }

    case "LOGOUT":
      localStorage.clear();
      Cookies.remove('accessToken', { path: '/', domain: cookie_domain });
      Cookies.remove('expires_in', { path: '/', domain: cookie_domain });
      Cookies.remove('user', { path: '/', domain: cookie_domain });
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }

    default:
      return state;
  }
}


export default function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AuthContext.Provider value={{
      state,
      dispatch
    }}>
      <Routes>
        <Route path="/" element={<LandingpageIndex />} />
        <Route path="/journey/:id" element={<ExploreJourney />} />
        <Route path="/journey/:id/video/:id" element={<Video />} />
      </Routes>
    </AuthContext.Provider>
  );
}