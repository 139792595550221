import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';
import { useTranslation } from 'react-i18next';
import {
    Container,
    Nav,
    Navbar,
    NavDropdown,
    DropdownButton,
    Dropdown
} from "react-bootstrap";
import './Navigationbar.scss';
import Logo from '../../assets/images/navigationbar/gened-logo.png';
import UserPlaceholder from '../../assets/images/navigationbar/user-placeholder.jpeg';
import idFlag from '../../assets/images/navigationbar/id-flag.svg';
import usaFlag from '../../assets/images/navigationbar/usa-flag.svg';

function NavigationBar() {
    const [navbar, setNavbar] = useState(false);
    const [locale] = useState(localStorage.getItem('locale') == null ? 'id' : localStorage.getItem('locale'));
    const { i18n } = useTranslation();
    const { dispatch } = useContext(AuthContext);

    const addNavbarShadow = () => {
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', addNavbarShadow)

    const handleSelect = (e) => {
        localStorage.setItem("locale", e)
        i18n.changeLanguage(locale);
        window.location.reload();
    }

    let navFlag;
    if (locale === "id") {
        navFlag = <img src={idFlag} alt='id flag' />
    } else {
        navFlag = <img src={usaFlag} alt='en flag' />
    }
    return (
        <Navbar
            className={navbar ? 'journey-custom-navbar journey-custom-navbar-onscroll' : 'journey-custom-navbar'}
            collapseOnSelect
            sticky="top"
            expand="lg"
            variant="light">
            <Container fluid>
                <Link to='/'>
                    <img className='journey-custom-navbar_logo' alt='logo' src={Logo} />
                </Link>
                <DropdownButton
                    title={navFlag}
                    className="language-picker"
                    onSelect={handleSelect}
                >
                    <Dropdown.Item eventKey="id"><img src={idFlag} alt='id flag' /> Indonesian</Dropdown.Item>
                    <Dropdown.Item eventKey="en"><img src={usaFlag} alt='en flag' /> English</Dropdown.Item>
                </DropdownButton>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <div className='space-between-menu'>
                            <Nav.Link href='https://gened.info/'>Home</Nav.Link>
                            <NavDropdown title="Program" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="https://learn.gened.info/module">Innovation Learning</NavDropdown.Item>
                                <NavDropdown.Item href="https://library.gened.info">Innovation Library</NavDropdown.Item>
                                <NavDropdown.Item href="https://community.gened.info">Innovation Community</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="https://gened.info/maintenance">Leaderboard</Nav.Link>
                        </div>

                        <NavDropdown className='profile-dropdown'
                            title={
                                <div className="user-profile">
                                    <img className="user-img"
                                        src={UserPlaceholder}
                                        alt="user pic"
                                    />
                                </div>
                            }
                            id="basic-nav-dropdown">

                            <NavDropdown.Item eventKey={1.1} href="https://gened.info/maintenance">Profile</NavDropdown.Item>
                            <NavDropdown.Item eventKey={1.1} onClick={() => dispatch({ type: "LOGOUT" })}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;
